import { useLocation, useParams } from 'react-router-dom';

const ErrorRoute: React.FC = () => {
	const { code } = useParams();
	const location = useLocation();
	console.log('🚀 ~ location:', location);

	let errorMessage = '';
	switch (code) {
		case '400':
			errorMessage = 'Bad Request';
			break;
		case '401':
			errorMessage = 'Unauthorized';
			break;
		case '403':
			errorMessage = 'Forbidden';
			break;
		case '404':
			errorMessage = 'Not Found';
			break;
		case '405':
			errorMessage = 'Method Not Allowed';
			break;
		case '408':
			errorMessage = 'Request Timeout';
			break;
		case '409':
			errorMessage = 'Conflict';
			break;
		case '410':
			errorMessage = 'Gone';
			break;
		case '413':
			errorMessage = 'Payload Too Large';
			break;
		case '414':
			errorMessage = 'URI Too Long';
			break;
		case '415':
			errorMessage = 'Unsupported Media Type';
			break;
		case '429':
			errorMessage = 'Too Many Requests';
			break;
		case '500':
			errorMessage = 'Internal Server Error';
			break;
		case '501':
			errorMessage = 'Not Implemented';
			break;
		case '502':
			errorMessage = 'Bad Gateway';
			break;
		case '503':
			errorMessage = 'Service Unavailable';
			break;
		case '504':
			errorMessage = 'Gateway Timeout';
			break;
		default:
			errorMessage = 'An unknown error occurred';
	}

	return (
		<div>
			<h1>Error {code}</h1>
			<p>{errorMessage}</p>
		</div>
	);
};

export default ErrorRoute;

import { Item, Category } from '~/types/user.types';

export const capitalize = (str: string = '') => str.charAt(0).toUpperCase() + str.slice(1);

export const orderByValue = (arr: any, key: string): any[] => arr.sort((a: any, b: any) => a[key] - b[key]);

export const orderByKey = (obj: Record<string, any>): Record<string, any> => {
	const sortedKeys = Object.keys(obj).sort();
	const sortedObj: Record<string, any> = {};
	for (const key of sortedKeys) {
		sortedObj[key] = obj[key];
	}
	return sortedObj;
};

export const countNestedValues = (data: any, value: keyof Item, key?: keyof Category, countTrue: boolean = true): number => {
	let totalCount = 0;

	if (Array.isArray(data)) {
		totalCount = data.filter((item) => (countTrue ? item[value] === true : item[value] !== true)).length;
	} else if (key && Array.isArray(data[key])) {
		totalCount = (data[key] as Item[]).filter((item) => (countTrue ? item[value] === true : item[value] !== true)).length;
	} else {
		for (const nestedKey in data) {
			if (key && Array.isArray(data[nestedKey][key])) {
				totalCount += (data[nestedKey][key] as Item[]).filter((item) =>
					countTrue ? item[value] === true : item[value] !== true
				).length;
			}
		}
	}

	return totalCount;
};

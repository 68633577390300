/* eslint-disable @typescript-eslint/no-unused-vars */
import styled, { css } from 'styled-components';
import { StyledButtonProps } from '~/types/button.types';
import { ThemeModel } from '~/types/theme.types';
import { VariantTypes } from '~/types/variant.types';

const getVariantStyles = (variant: VariantTypes, theme: ThemeModel) => {
	switch (variant) {
		case VariantTypes.Filled:
			return css`
				color: ${theme.mode === 'dark' ? theme.main.light.text : theme.main.dark.text};
				background-color: ${theme.mode === 'dark' ? theme.main.light.surface : theme.main.dark.surface};
			`;
		case VariantTypes.Outlined:
			return css`
				color: ${theme.mode === 'dark' ? theme.main.dark.text : theme.main.light.text};
				border-color: ${theme.mode === 'dark' ? theme.main.light.surface : theme.main.dark.surface};
			`;
		case VariantTypes.Text:
			return css`
			color: ${theme.mode === 'dark' ? theme.main.light.text : theme.main.dark.text};
				&:hover {
					color: ${theme.mode === 'dark' ? theme.main.light.text : theme.main.dark.text};
					background-color: ${theme.mode === 'dark' ? theme.main.light.surface : theme.main.dark.surface};
				}
			`;
	}
};

const StyledButton = styled(({ component: Component = 'button', variant, startIcon, endIcon, ...rest }: StyledButtonProps) => {
	return <Component {...rest} />;
})<StyledButtonProps>`
	${({ variant = VariantTypes.Filled, theme }) => getVariantStyles(variant, theme)}
`;

export default StyledButton;

import { useForm } from 'react-hook-form';
import Input from '~/components/input/input.component';
import { useModal } from '~/hooks/modal.hooks';
import ReactQueryHooks from '~/hooks/react-query.hooks';
import { FormValidationEnum } from '~/types/forms.types';
import { Item } from '~/types/user.types';
import styles from '../modal.module.scss';
import Button from '~/components/button/button.component';
import { ButtonTypeEnum } from '~/types/button.types';

const ItemModal = () => {
	const { data: currentUser } = ReactQueryHooks.currentUser();
	const { closeModal, modalData } = useModal();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<{ item: Item }>();

	const addItemToCategoryMutation = ReactQueryHooks.addItemToCategory(closeModal);

	const onAddItem = (data: { item: Item }) => {
		if (currentUser) {
			addItemToCategoryMutation.mutate({ uid: currentUser.uid, categoryName: modalData.category, item: data.item });
		}
	};

	return (
		<>
			<div className={styles.label}>
				<h1>Add Item</h1>
			</div>

			<form className={styles.modal_form} onSubmit={handleSubmit(onAddItem)}>
				<Input
					type='text'
					placeholder='Item name'
					register={{ ...register('item.label', { required: FormValidationEnum.REQUIRED }) }}
					error={errors.item?.label}
				/>
			</form>

			<Button type={ButtonTypeEnum.Submit}>
				<i className='ri-add-line' />
			</Button>
		</>
	);
};

export default ItemModal;

import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import 'remixicon/fonts/remixicon.css';
import './index.scss';

if ('serviceWorker' in navigator) {
	window.addEventListener('load', () => {
		navigator.serviceWorker
			.register('/service-worker.js')
			.then((registration) => {
				console.log('Service Worker registered:', registration.scope);
			})
			.catch((err) => {
				console.log('Service Worker registration failed:', err);
			});
	});
}

ReactDOM.createRoot(document.getElementById('root')!).render(
	// <React.StrictMode>
	<App />
	// </React.StrictMode>
);


// import { useLastLocation } from "~/hooks/location.hooks";

interface LocationWrapProps {
	children: React.ReactNode;
}

const LocationWrap: React.FC<LocationWrapProps> = ({ children }) => {
	// const locationInfo = useLastLocation();

	return (
		<>
			{/* <div>
				<p>Last Location: {locationInfo?.lastLocation}</p>
				<p>Current Location: {locationInfo?.currentLocation}</p>
			</div> */}
			{children}
		</>
	);
};

export default LocationWrap;

import { QueryClient, QueryClientProvider } from 'react-query';
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';
import { MS_IN_DAY } from '~/utils/constants.utils';
import type { QueryKey } from 'react-query';

const cacheTime = MS_IN_DAY * 5;

interface QueryProviderProps {
	children: React.ReactNode;
}

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: MS_IN_DAY,
			cacheTime,
		},
	},
});

const localStoragePersistor = createWebStoragePersistor({
	storage: window.localStorage,
});

// Add query keys that will not persist upon refresh. Ex: FirebaseCollectionEnum.CLIENTS
const doNotPersistQueries: QueryKey[] = [];

persistQueryClient({
	queryClient,
	persistor: localStoragePersistor,
	maxAge: cacheTime,
	hydrateOptions: {},
	dehydrateOptions: {
		shouldDehydrateQuery: ({ queryKey }) => !doNotPersistQueries.includes(queryKey),
	},
});

const QueryProvider: React.FC<QueryProviderProps> = ({ children }) => (
	<QueryClientProvider client={queryClient}>
		{children}
	</QueryClientProvider>
);

export default QueryProvider;

import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom';
import AuthenticationRoute from './routes/public/authentication/authentication.route';
import QueryProvider from './providers/react-query.provider';
import PrivateRoute from './routes/private/private.route';
import PublicRoute from './routes/public/public.route';
import DashboardRoute from './routes/private/dashboard/dashboard.route';
import CategoryRoute from './routes/private/category/category.route';
import ProfileRoute from './routes/private/profile/profile.route';
import ErrorRoute from './routes/error/error.route';
import { ThemeProvider } from './context/theme.context';
import CssBaseline from './components/css-baseline/css-baseline.component';
import { Slide, ToastContainer } from 'react-toastify';
import { LastLocationProvider } from './context/location.context';
import LocationWrap from './components/location-wrap/location-wrap.component';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ModalProvider } from './context/modal.context';
import 'react-toastify/dist/ReactToastify.css';

const App: React.FC = () => {
	return (
		<QueryProvider>
			<ThemeProvider>
				<BrowserRouter>
					<LastLocationProvider>
						<LocationWrap>
							<ModalProvider>
								<Routes>
									{/* Public Routes */}
									<Route path='/' element={<PublicRoute />}>
										<Route index element={<Navigate to='/authentication' />} />
										<Route path='authentication' element={<AuthenticationRoute />} />
									</Route>

									{/* Private Routes */}
									<Route path='/private' element={<PrivateRoute />}>
										<Route index element={<Navigate to='/dashboard' replace />} />
										<Route path='dashboard' element={<DashboardRoute />} />
										<Route path='category/:categoryName' element={<CategoryRoute />} />
										<Route path='profile/:uid' element={<ProfileRoute />} />
									</Route>

									{/* Error Routes */}
									<Route path='/error/:code' element={<ErrorRoute />} />
									<Route path='*' element={<Navigate to='/error/404' />} />
								</Routes>
							</ModalProvider>
						</LocationWrap>
					</LastLocationProvider>
				</BrowserRouter>
				<ToastContainer
					pauseOnFocusLoss={false}
					pauseOnHover={false}
					limit={3}
					autoClose={1500}
					transition={Slide}
					theme='dark'
					position='bottom-center'
					newestOnTop
				/>
				<CssBaseline />
			</ThemeProvider>
			<ReactQueryDevtools initialIsOpen={true} position='top-right' />
		</QueryProvider>
	);
};

export default App;

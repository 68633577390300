import { Navigate, Outlet } from 'react-router-dom';
import Modal from '~/components/modal/modal.component';
import Navigation from '~/components/navigation/navigation.component';
import { ReactQueryEnum } from '~/enums/react-query.enums';
import ReactQueryHooks from '~/hooks/react-query.hooks';
import { UserModel } from '~/types/user.types';

const PrivateRoute: React.FC = () => {
	const currentUser = ReactQueryHooks.read<UserModel>(ReactQueryEnum.CURRENT_USER);

	if (!currentUser) {
		return <Navigate to='/authentication' />;
	}

	return (
		<>
			<Outlet />
			<Navigation />
			<Modal />
		</>
	);
};

export default PrivateRoute;

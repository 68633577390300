/* eslint-disable @typescript-eslint/no-unused-vars */
import hexAlpha from 'hex-alpha';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { StyledInputProps } from '~/types/input.types';
import type { ThemeModel } from '~/types/theme.types';
import { VariantTypes } from '~/types/variant.types';

const getVariantStyles = (variant: VariantTypes, theme: ThemeModel, disabled: boolean) => {
	const setDisable = (color: string) => (disabled ? hexAlpha(color, 0.7) : color);

	if (variant === VariantTypes.Filled) {
		return css`
			background-color: ${setDisable(theme.main.lighter.surface)};
			color: ${setDisable(theme.main.lighter.text)};
			&::placeholder {
				color: ${setDisable(hexAlpha(theme.main.lighter.text, 0.5))};
			}
		`;
	}

	if (variant === VariantTypes.Outlined) {
		return css`
			color: ${setDisable(theme.main.base.text)};
			border-color: ${setDisable(theme.main.base.surface)};
			&::placeholder {
				color: ${setDisable(theme.main.base.text)};
			}
		`;
	}

	if (variant === VariantTypes.Text) {
		return css`
			color: ${setDisable(theme.main.base.text)};
			/* &:hover:not(:disabled) {
				border-color: ${setDisable(theme.main.base.surface)};
			} */
			&::placeholder {
				color: ${setDisable(theme.main.base.text)};
			}
		`;
	}

	return '';
};

const StyledInputComponent = forwardRef((props: StyledInputProps, ref) => {
	const { component: Component = 'input', theme, variant, ...attributes } = props;
	return <Component ref={ref} {...attributes} />;
});

StyledInputComponent.displayName = 'StyledInputComponent';

const StyledInput = styled(StyledInputComponent)<StyledInputProps>`
	${({ variant = VariantTypes.Filled, theme, disabled = false }) => getVariantStyles(variant, theme, disabled)}
`;

export default StyledInput;

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import FirebaseHooks from '~/hooks/firebase.hooks';
import ReactQueryHooks from '~/hooks/react-query.hooks';
import { AuthenticationForm } from '~/types/authentication.types';
import styles from './authentication.module.scss';
import Button from '~/components/button/button.component';
import Input from '~/components/input/input.component';
import { FormValidationEnum } from '~/types/forms.types';
import { toast } from 'react-toastify';
import { ReactComponent as Logo } from '~/assets/logo.svg';
import baby from '~/assets/baby.svg';
import { ButtonTypeEnum } from '~/types/button.types';

const AuthenticationRoute: React.FC = () => {
	const navigate = useNavigate();
	const { data: currentUser, refetch } = ReactQueryHooks.currentUser();
	const signInWithEmailAndPassword = ReactQueryHooks.signInWithEmailAndPassword();
	const signUpWithEmailAndPassword = ReactQueryHooks.signUpWithEmailAndPassword();

	const [isSignUpMode, setIsSignUpMode] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<AuthenticationForm>({
		defaultValues: {
			email: '',
			password: '',
		},
	});

	const handleGoogleSignIn = async () => {
		try {
			await FirebaseHooks.signInWithGoogle();
			refetch();
		} catch (error: any) {
			toast.error(error.message);
		}
	};

	const handleEmailPasswordSignIn = (data: AuthenticationForm) => {
		try {
			signInWithEmailAndPassword.mutate({
				email: data.email,
				password: data.password,
			});
			refetch();
		} catch (error: any) {
			toast.error(error.message);
		}
	};

	const handleEmailPasswordSignUp = (data: AuthenticationForm) => {
		try {
			signUpWithEmailAndPassword.mutate({
				email: data.email,
				password: data.password,
				displayName: data.displayName,
			});
			refetch();
		} catch (error: any) {
			toast.error(error.message);
		}
	};

	useEffect(() => {
		if (currentUser) {
			navigate('/private/dashboard');
		}
	}, [currentUser]);

	return (
		<div id={styles.authentication} className={`${styles.authentication}`}>
			<div className={styles.baby} style={{ backgroundImage: `url(${baby})` }}>
				<div className={styles.logo}>
					<Logo />
				</div>
				<form
					onSubmit={handleSubmit(isSignUpMode ? handleEmailPasswordSignUp : handleEmailPasswordSignIn)}
					className={styles.form}>
					<div className='form_body'>
						{isSignUpMode && (
							<Input
								type='text'
								placeholder='Username'
								register={{ ...register('displayName', { required: FormValidationEnum.REQUIRED }) }}
								error={errors.displayName}
							/>
						)}
						<Input
							type='email'
							placeholder='Email'
							register={{ ...register('email', { required: FormValidationEnum.REQUIRED }) }}
							error={errors.displayName}
						/>
						<Input
							type='password'
							placeholder='Password'
							register={{ ...register('password', { required: FormValidationEnum.REQUIRED }) }}
							error={errors.displayName}
						/>
					</div>
					<div className={`form_footer ${styles.buttons}`}>
						<Button onClick={() => setIsSignUpMode(!isSignUpMode)}>
							{isSignUpMode ? 'Sign In' : 'Sign Up'}
							<i className='ri-arrow-up-line' />
						</Button>
						<div className='actions'>
							<Button onClick={handleGoogleSignIn}>
								<i className='ri-google-line' />
							</Button>
							<Button type={ButtonTypeEnum.Submit}>
								<i className='ri-arrow-right-line' />
							</Button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default AuthenticationRoute;

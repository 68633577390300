import { createContext, useState } from 'react';

type ModalContextProps = {
	isModalOpen: boolean;
	modalData: any;
	openModal: (data?: any) => void;
	closeModal: () => void;
	setModalContent: (data: any) => void;
};

export const ModalContext = createContext<ModalContextProps | undefined>(undefined);

interface ModalProviderProps {
	children: React.ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
	const [isModalOpen, setModalOpen] = useState(false);
	const [modalData, setModalData] = useState<any>(null);

	const setModalContent = (data: any) => {
		setModalData(data);
	};

	const openModal = (data?: any) => {
		setModalData(data);
		setModalOpen(true);
	};

	const closeModal = () => {
		setModalData(null);
		setModalOpen(false);
	};

	return (
		<ModalContext.Provider value={{ isModalOpen, modalData, openModal, closeModal, setModalContent }}>
			{children}
		</ModalContext.Provider>
	);
};

import React, { createContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface LocationInfo {
	lastLocation: string | null;
	currentLocation: string;
}

export const LastLocationContext = createContext<LocationInfo | null>(null);

interface LastLocationProviderProps {
	children: React.ReactNode;
}

export const LastLocationProvider: React.FC<LastLocationProviderProps> = ({ children }) => {
	const [lastLocation, setLastLocation] = useState<string | null>(null);
	const location = useLocation();
	const currentLocationRef = useRef(location.pathname);

	useEffect(() => {
		if (location.pathname !== currentLocationRef.current) {
			setLastLocation(currentLocationRef.current);
			currentLocationRef.current = location.pathname;
		}
	}, [location]);

	const locationInfo: LocationInfo = {
		lastLocation,
		currentLocation: location.pathname,
	};

	return <LastLocationContext.Provider value={locationInfo}>{children}</LastLocationContext.Provider>;
};
import styles from './input.module.scss';
import { VariantTypes } from '~/types/variant.types';
import StyledInput from './input.styled';
import { InputProps } from '~/types/input.types';
import { useTheme } from '~/hooks/theme.hooks';

const classVariant = (variant?: VariantTypes) => (variant === VariantTypes.Text ? styles.text_input : styles.input);

const Input: React.FC<InputProps> = ({
	variant,
	type,
	register,
	value,
	defaultValue,
	placeholder,
	step,
	disabled,
	className,
	style,
	icon,
	error,
	onChange,
}) => {
	const { theme } = useTheme();

	const internalOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (register && register.onChange) {
			register.onChange(event);
		}
		if (onChange) {
			onChange(event);
		}
	};

	return (
		<div
			className={
				error ? `${styles.input_container} ${styles.error}` : className ? `${styles.input_container} ${className}` : styles.input_container
			}>
			<div className={styles.input}>
				<StyledInput
					{...register}
					theme={theme}
					variant={variant}
					type={type}
					placeholder={placeholder}
					value={value}
					defaultValue={defaultValue}
					step={step}
					disabled={disabled}
					onChange={internalOnChange}
					onClick={(event: React.MouseEvent<HTMLInputElement>) => event.stopPropagation()}
					className={classVariant(variant)}
					style={style}
				/>
				{error && (
					<div className={styles.warning} style={{ color: theme.main.lighter.surface }}>
						<i className='ri-error-warning-line' />
						{/* {error.message} */}
					</div>
				)}
			</div>
			{icon}
		</div>
	);
};

export default Input;

import type { ThemeModel } from './theme.types';
import type { ElementType } from 'react';
import { VariantTypes } from './variant.types';

export interface ButtonProps {
	children: React.ReactNode;
	variant?: VariantTypes;
	type?: ButtonTypeEnum;
	disabled?: boolean;
	className?: string;
	startIcon?: React.ReactNode;
	endIcon?: React.ReactNode;
	onClick?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => Promise<void> | void) | (() => any);
}

export interface StyledButtonProps extends ButtonProps {
	theme: ThemeModel;
	component?: ElementType;
}

export enum ButtonTypeEnum {
	Submit = 'submit',
	Button = 'button',
	Reset = 'reset',
}

import { Categories, ItemWithCategory } from '~/types/user.types';

// const sortItemsByDate = (data: ItemWithCategory[]) => {
// 	return data.item.sort((a, b) => {
// 		const aSeconds = a.added.seconds;
// 		const bSeconds = b.added.seconds;
// 		return bSeconds === aSeconds ? b.added.nanoseconds - a.added.nanoseconds : bSeconds - aSeconds;
// 	});
// };

const sortItemsByDate = (data: ItemWithCategory[]): ItemWithCategory[] => {
  return data.sort((a, b) => {
    const aSeconds = a.item.added.seconds;
    const bSeconds = b.item.added.seconds;
    return bSeconds === aSeconds ? b.item.added.nanoseconds - a.item.added.nanoseconds : bSeconds - aSeconds;
  });
};

export const lastItems = (obj: Categories) => {
	const allItems: ItemWithCategory[] = [];

	for (const [categoryName, category] of Object.entries(obj)) {
		if (category?.items?.length) {
			const itemsWithCategory = category.items.map((item) => ({
				item: item,
				category: {
					color: category.color || '',
					name: categoryName,
				},
			}));
			allItems.push(...itemsWithCategory);
		}
	}

	const sortedItems = sortItemsByDate(allItems);
	return sortedItems;
};

import { useNavigate } from 'react-router-dom';
import ReactQueryHooks from '~/hooks/react-query.hooks';
import styles from './dashboard.module.scss';
import { capitalize, countNestedValues, orderByKey } from '~/functions/utils.functions';
import Input from '~/components/input/input.component';
import Card from '~/components/card/card.component';
import { lastItems } from '~/functions/category.function';
import { useState } from 'react';

const DashboardRoute = () => {
	const navigate = useNavigate();
	const { data: currentUser } = ReactQueryHooks.currentUser();
	const [searchTerm, setSearchTerm] = useState('');

	const handleCategoryClick = (categoryName: string) => {
		navigate(`/private/category/${categoryName}`);
	};

	return (
		<div className='container'>
			<div className='header'>
				{currentUser?.categories ? (
					<h1>
						Hi {currentUser?.displayName || currentUser?.email}!<br />
						You have{' '}
						<span className={styles.counter}>{countNestedValues(currentUser?.categories, 'purchased', 'items', false)}</span>{' '}
						items to purchase
					</h1>
				) : (
					<h1>Hi {currentUser?.displayName || currentUser?.email}!</h1>
				)}
			</div>

			<div className='body'>
				{Object.keys(currentUser?.categories || {}).length > 0 ? (
					<>
						<Input type='text' placeholder='Search item' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
						<ul className={styles.categories}>
							{Object.entries(orderByKey(currentUser?.categories || {}))
								.map(([key, value]) => (
									<li
										key={key}
										className={styles.category}
										onClick={() => handleCategoryClick(key)}
										style={{ color: value.color }}>
										<div>{capitalize(key)}</div>
										<div>{value.items ? value.items.length : 0}</div>
									</li>
								))}
						</ul>
					</>
				) : (
					<div>No items to display</div>
				)}

				{currentUser?.categories && (
					<div className={styles.latest_items}>
						<h4>Latest items</h4>
						<div className={styles.cards_container}>
							{lastItems(currentUser?.categories || {})
								.filter((data) => data.item.label.toLowerCase().includes(searchTerm.toLowerCase()))
								.slice(0, 5)
								.map((data) => (
									<Card key={data.item.uid} data={data} />
								))}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default DashboardRoute;

import React, { createContext, useState, useCallback } from 'react';
import { ReactQueryEnum } from '~/enums/react-query.enums';
import { lightenHexColor, darkenHexColor, contrastHexColor, generateCssVariables } from '~/functions/colors.function';
import { genColorSetPalette } from '~/functions/palette.functions';
import ReactQueryHooks from '~/hooks/react-query.hooks';
import type {
	ThemeColorModeModel,
	ThemeColorPropsModel,
	ThemeColorSetPropsModel,
	ThemeModeOptions,
	ThemeModel,
} from '~/types/theme.types';
import { UserModel } from '~/types/user.types';

const defaultProps: ThemeColorPropsModel = {
	mode: 'dark',
	background: {
		light: '#ececec',
		dark: '#111111',
	},
	text: {
		light: '#FFF5FE',
		dark: '#010001',
	},
	accent: {
		dark: '#A3DD4D',
		light: '#445D20',
	},
	status: {
		success: {
			dark: '#64F25A',
			light: '#265A22',
		},
		warning: {
			dark: '#FFC107',
			light: '#FF9800',
		},
		error: {
			dark: '#FF3D00',
			light: '#FF0000',
		},
		info: {
			dark: '#31A8E3',
			light: '#183E4F',
		},
	},
	palette: {
		primary: {
			light: '#5D204A',
			dark: '#DD4D7D',
		},
		secondary: {
			light: '#20565D',
			dark: '#4DC7DD',
		},
	},
};

const deepMerge = (target: any, source: any) => {
	const isObject = (obj: any) => obj && typeof obj === 'object';

	if (!isObject(target) || !isObject(source)) return source;

	const merged = { ...target };
	for (const key in source) {
		if (Object.prototype.hasOwnProperty.call(source, key)) {
			merged[key] = isObject(target[key]) && isObject(source[key]) ? deepMerge(target[key], source[key]) : source[key];
		}
	}

	return merged;
};

const modeColor = (set: ThemeColorModeModel, mode: ThemeModeOptions): string => (mode === 'light' ? set.light : set.dark);

const setFullTheme = (props: ThemeColorPropsModel): ThemeModel => ({
	mode: props.mode,
	main: genColorSetPalette(modeColor(props.background, props.mode), props.text.light, props.text.dark),
	accent: modeColor(props.accent, props.mode),
	status: {
		success: genColorSetPalette(modeColor(props.status.success, props.mode), props.text.light, props.text.dark),
		warning: genColorSetPalette(modeColor(props.status.warning, props.mode), props.text.light, props.text.dark),
		error: genColorSetPalette(modeColor(props.status.error, props.mode), props.text.light, props.text.dark),
		info: genColorSetPalette(modeColor(props.status.info, props.mode), props.text.light, props.text.dark),
	},
	palette: {
		primary: genColorSetPalette(modeColor(props.palette.primary, props.mode), props.text.light, props.text.dark),
		secondary: genColorSetPalette(modeColor(props.palette.secondary, props.mode), props.text.light, props.text.dark),
	},
	functions: {
		lightenHexColor,
		darkenHexColor,
		contrastHexColor,
	},
});

export const ThemeContext = createContext<{
	theme: ThemeModel;
	setTheme: (updatedTheme: Partial<ThemeColorSetPropsModel>) => void;
	resetTheme: () => void;
}>({
	theme: setFullTheme(defaultProps),
	setTheme: () => {},
	resetTheme: () => {},
});

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const currentUser = ReactQueryHooks.read<UserModel>(ReactQueryEnum.CURRENT_USER);
	const [props, setThemeState] = useState<ThemeColorPropsModel>({
		...defaultProps,
		mode: currentUser ? currentUser?.preferredTheme : 'dark',
	});

	const setTheme = useCallback((updatedTheme: Partial<ThemeColorSetPropsModel>) => {
		setThemeState((prevTheme) => deepMerge({ ...prevTheme }, updatedTheme));
	}, []);

	const resetTheme = useCallback(() => {
		setThemeState((prevTheme) =>
			deepMerge({ ...prevTheme }, { ...defaultProps, mode: currentUser ? currentUser?.preferredTheme : 'dark' })
		);
	}, []);

	const theme: ThemeModel = setFullTheme(props);

	const rootStyleThemes = [{ main: theme.main, primary: theme.palette.primary }];
	generateCssVariables(rootStyleThemes);

	return <ThemeContext.Provider value={{ theme, setTheme, resetTheme }}>{children}</ThemeContext.Provider>;
};

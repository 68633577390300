import { Navigate, Outlet } from 'react-router-dom';
import { ReactQueryEnum } from '~/enums/react-query.enums';
import ReactQueryHooks from '~/hooks/react-query.hooks';
import { UserModel } from '~/types/user.types';

const PublicRoute: React.FC = () => {
	const currentUser = ReactQueryHooks.read<UserModel>(ReactQueryEnum.CURRENT_USER);

	if (currentUser) {
		return <Navigate to='/private/dashboard' replace />;
	}

	return <Outlet />;
};

export default PublicRoute;
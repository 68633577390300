import { useQuery, useMutation } from 'react-query';
import FirebaseHooks from './firebase.hooks';
import { ReactQueryEnum } from '~/enums/react-query.enums';
import { queryClient } from '~/providers/react-query.provider';
import { auth } from '~/utils/firebase.utils';
import { Item } from '~/types/user.types';
import { ThemeModeOptions } from '~/types/theme.types';

const useReadQuery = <T,>(queryKey: ReactQueryEnum) => queryClient.getQueryData<T>(queryKey);
const useRemoveQuery = (queryKey?: ReactQueryEnum) => queryClient.removeQueries(queryKey);
const useRefetchQuery = (queryKey: ReactQueryEnum) => () => queryClient.refetchQueries(queryKey);

const setQuery = (queryKey: ReactQueryEnum, data: any) => {
	return queryClient.setQueryData(queryKey, data);
};

const useCurrentUser = () => {
	return useQuery(ReactQueryEnum.CURRENT_USER, () => FirebaseHooks.getUser(), {
		enabled: !!auth.currentUser,
	});
};

const useSignInWithEmailAndPassword = () => {
	return useMutation(
		({ email, password }: { email: string; password: string }) => FirebaseHooks.signInWithEmailAndPassword(email, password),
		{
			onSettled: () => {
				queryClient.invalidateQueries(ReactQueryEnum.CURRENT_USER);
			},
		}
	);
};

const useSignUpWithEmailAndPassword = () => {
	return useMutation(
		({ email, password, displayName }: { email: string; password: string; displayName: string }) =>
			FirebaseHooks.signUpWithEmailAndPassword(email, password, displayName),
		{
			onSettled: () => {
				queryClient.invalidateQueries(ReactQueryEnum.CURRENT_USER);
			},
		}
	);
};

const useUpdateUserProfile = () => {
	return useMutation(
		({
			uid,
			displayName,
			password,
			email,
			preferredTheme,
			showToaster,
		}: {
			uid: string;
			displayName?: string;
			password?: string;
			email?: string;
			preferredTheme?: ThemeModeOptions;
			showToaster?: boolean;
		}) => FirebaseHooks.updateUserProfile(uid, displayName, password, email, preferredTheme, showToaster),
		{
			onSettled: () => {
				queryClient.invalidateQueries(ReactQueryEnum.CURRENT_USER);
			},
		}
	);
};

const useAddCategory = (onSuccess?: () => void) => {
	return useMutation(
		(params: { uid: string; categoryName: string, color: string }) => FirebaseHooks.addCategory(params.uid, params.categoryName, params.color),
		{
			onSettled: () => {
				queryClient.invalidateQueries(ReactQueryEnum.CURRENT_USER);
			},
			onSuccess: async () => {
				await queryClient.refetchQueries(ReactQueryEnum.CURRENT_USER, { active: true });
				onSuccess && onSuccess();
			},
		}
	);
};

const useRemoveCategory = (onSuccess?: () => void) => {
	return useMutation(
		(params: { uid: string; categoryName: string }) => FirebaseHooks.removeCategory(params.uid, params.categoryName),
		{
			onSettled: () => {
				queryClient.invalidateQueries(ReactQueryEnum.CURRENT_USER);
			},
			onSuccess: async () => {
				await queryClient.refetchQueries(ReactQueryEnum.CURRENT_USER, { active: true });
				onSuccess && onSuccess();
			},
		}
	);
};

const useUpdateCategoryName = () => {
	return useMutation(
		(params: { uid: string; oldCategoryName: string; newCategoryName: string }) =>
			FirebaseHooks.updateCategoryName(params.uid, params.oldCategoryName, params.newCategoryName),
		{
			onSettled: () => {
				queryClient.invalidateQueries(ReactQueryEnum.CURRENT_USER);
			},
			onSuccess: () => {
				queryClient.invalidateQueries(ReactQueryEnum.CURRENT_USER);
			},
		}
	);
};

const useUpdateCategoryColor = () => {
	return useMutation(
		(params: { uid: string; categoryName: string; color: string }) =>
			FirebaseHooks.updateCategoryColor(params.uid, params.categoryName, params.color),
		{
			onSettled: () => {
				queryClient.invalidateQueries(ReactQueryEnum.CURRENT_USER);
			},
		}
	);
};

const useAddItemToCategory = (onSuccess?: () => void) => {
	return useMutation(
		(params: { uid: string; categoryName: string; item: Item }) =>
			FirebaseHooks.addItemToCategory(params.uid, params.categoryName, params.item),
		{
			onSettled: () => {
				queryClient.invalidateQueries(ReactQueryEnum.CURRENT_USER);
			},
			onSuccess: async () => {
				await queryClient.refetchQueries(ReactQueryEnum.CURRENT_USER, { active: true });
				onSuccess && onSuccess();
			},
		}
	);
};

const useRemoveItemFromCategory = () => {
	return useMutation(
		(params: { uid: string; categoryName: string; item: Item }) =>
			FirebaseHooks.removeItemFromCategory(params.uid, params.categoryName, params.item),
		{
			onSettled: () => {
				queryClient.invalidateQueries(ReactQueryEnum.CURRENT_USER);
			},
		}
	);
};

const useUpdateItemInCategory = () => {
	return useMutation(
		(params: { uid: string; categoryName: string; oldItem: Item; newItem: Item }) =>
			FirebaseHooks.updateItemInCategory(params.uid, params.categoryName, params.oldItem, params.newItem),
		{
			onSettled: () => {
				queryClient.invalidateQueries(ReactQueryEnum.CURRENT_USER);
			},
		}
	);
};

const ReactQueryHooks = {
	set: setQuery,
	read: useReadQuery,
	remove: useRemoveQuery,
	refetch: useRefetchQuery,
	currentUser: useCurrentUser,
	updateUserProfile: useUpdateUserProfile,
	signInWithEmailAndPassword: useSignInWithEmailAndPassword,
	signUpWithEmailAndPassword: useSignUpWithEmailAndPassword,
	addCategory: useAddCategory,
	removeCategory: useRemoveCategory,
	updateCategoryName: useUpdateCategoryName,
	updateCategoryColor: useUpdateCategoryColor,
	addItemToCategory: useAddItemToCategory,
	removeItemFromCategory: useRemoveItemFromCategory,
	updateItemInCategory: useUpdateItemInCategory,
};

export default ReactQueryHooks;

import { VariantTypes } from '~/types/variant.types';
import { useNavigate } from 'react-router-dom';
import { ReactQueryEnum } from '~/enums/react-query.enums';
import FirebaseHooks from '~/hooks/firebase.hooks';
import ReactQueryHooks from '~/hooks/react-query.hooks';
import { UserModel } from '~/types/user.types';
import Button from '~/components/button/button.component';
import { useModal } from '~/hooks/modal.hooks';
import styles from './navigation.module.scss';

const Navigation = () => {
	const currentUser = ReactQueryHooks.read<UserModel>(ReactQueryEnum.CURRENT_USER);
	const navigate = useNavigate();
	const { openModal, modalData } = useModal();

	const handleSignOut = async () => {
		try {
			await FirebaseHooks.signOut();
			ReactQueryHooks.remove(ReactQueryEnum.CURRENT_USER);
			navigate('/authentication');
		} catch (error: any) {
			console.log('error', error.message);
		}
	};

	return (
		<div className={styles.navigation_bar}>
			<div className={styles.navigation}>
				<Button variant={VariantTypes.Filled} onClick={() => navigate('/private/dashboard')}>
					<i className='ri-home-line' />
				</Button>
				<Button variant={VariantTypes.Filled}>
					<i className='ri-bar-chart-line' />
				</Button>
				<div className={styles.add}>
					<button onClick={() => openModal(modalData)}>
						<i className='ri-add-line' />
					</button>
				</div>
				<Button variant={VariantTypes.Filled} onClick={() => navigate(`/private/profile/${currentUser?.uid}`)}>
					<i className='ri-user-line' />
				</Button>
				<Button variant={VariantTypes.Filled} onClick={handleSignOut}>
					<i className='ri-logout-circle-line' />
				</Button>
			</div>
		</div>
	);
};

export default Navigation;

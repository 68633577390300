import { useForm } from 'react-hook-form';
import Button from '~/components/button/button.component';
import Input from '~/components/input/input.component';
import { useModal } from '~/hooks/modal.hooks';
import ReactQueryHooks from '~/hooks/react-query.hooks';
import { CategoryForm } from '~/types/add-forms.types';
import { ButtonTypeEnum } from '~/types/button.types';
import { FormValidationEnum } from '~/types/forms.types';
import { useState } from 'react';
import { useTheme } from '~/hooks/theme.hooks';
import styles from '../modal.module.scss';

const CategoryModal = () => {
	const { theme } = useTheme();
	const { data: currentUser } = ReactQueryHooks.currentUser();
	const { closeModal } = useModal();
	const [color, setColor] = useState<string>(theme.main.base.text);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<CategoryForm>();

	const addCategoryMutation = ReactQueryHooks.addCategory(closeModal);

	const onAddCategory = (data: CategoryForm) => {
		if (currentUser) {
			addCategoryMutation.mutate({ uid: currentUser.uid, categoryName: data.categoryName, color: color });
		}
	};

	const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newColor = event.target.value;
		setColor(newColor);
	};

	return (
		<>
			<div className={styles.label}>
				<input
					className={styles.color_input}
					type='color'
					value={color}
					onChange={handleColorChange}
					onClick={(event) => event.stopPropagation()}
					style={{ backgroundColor: color }}
				/>
				<h1>Add new category</h1>
			</div>

			<form className={styles.modal_form} onSubmit={handleSubmit(onAddCategory)}>
				<Input
					type='text'
					placeholder='Category Name'
					register={{ ...register('categoryName', { required: FormValidationEnum.REQUIRED }) }}
					error={errors.categoryName}
				/>

				<Button type={ButtonTypeEnum.Submit}>
					<i className='ri-add-line' />
				</Button>
			</form>
		</>
	);
};

export default CategoryModal;

import { ButtonTypeEnum } from '~/types/button.types';
import type { ButtonProps } from '~/types/button.types';
import StyledButton from './button.styled';
import { useTheme } from '~/hooks/theme.hooks';

const Button: React.FC<ButtonProps> = (props) => {
	const { theme } = useTheme();
	const { variant, type = ButtonTypeEnum.Button, children, className, startIcon, endIcon, onClick } = props;

	const handleOnClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		event.stopPropagation();
		onClick && onClick(event);
	}

	return (
		<StyledButton {...props} variant={variant} type={type} theme={theme} className={className} onClick={handleOnClick}>
			{startIcon && startIcon}
			{children}
			{endIcon && endIcon}
		</StyledButton>
	);
};

export default Button;

import { useNavigate, useParams } from 'react-router-dom';
import ReactQueryHooks from '~/hooks/react-query.hooks';
import { Category, Item } from '~/types/user.types';
import styles from './category.module.scss';
import Card from '~/components/card/card.component';
import Input from '~/components/input/input.component';
import Button from '~/components/button/button.component';
import { capitalize, countNestedValues } from '~/functions/utils.functions';
import { useEffect, useState } from 'react';
import { useModal } from '~/hooks/modal.hooks';

const CategoryRoute = () => {
	const navigate = useNavigate();
	const { categoryName } = useParams();
	const { data: currentUser } = ReactQueryHooks.currentUser();
	const category = categoryName ? currentUser?.categories?.[categoryName] || ({} as Category) : ({} as Category);
	const items = category.items || ([] as Array<Item>);
	const updateCategoryColorMutation = ReactQueryHooks.updateCategoryColor();
	const [color, setColor] = useState<string>(category.color);
	const [searchTerm, setSearchTerm] = useState('');
	const [showPurchasedOnly, setShowPurchasedOnly] = useState<boolean | null>(null);
	const { setModalContent } = useModal();

	const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newColor = event.target.value;
		setColor(newColor);
	};

	const handleSuccessfulRemoval = () => {
		if (categoryName) {
			navigate(`/private/dashboard`);
		}
	};

	const removeCategoryMutation = ReactQueryHooks.removeCategory(handleSuccessfulRemoval);

	const handleRemoveCategory = () => {
		const { uid } = currentUser || {};
		if (uid && categoryName) {
			removeCategoryMutation.mutate({ uid, categoryName });
		}
	};

	const onUpdateColor = (event: React.FocusEvent<HTMLInputElement, Element>) => {
		event.stopPropagation();
		const { uid } = currentUser || {};
		if (uid && categoryName && category.color !== color) {
			updateCategoryColorMutation.mutate({
				uid,
				categoryName,
				color: event.target.value,
			});
		}
	};

	const filteredItems = items.filter((item) => {
		const matchesSearchTerm = item.label.toLowerCase().includes(searchTerm.toLowerCase());

		if (showPurchasedOnly === true) {
			return matchesSearchTerm && item.purchased;
		}

		if (showPurchasedOnly === false) {
			return matchesSearchTerm && !item.purchased;
		}

		return matchesSearchTerm;
	});

	useEffect(() => {
		const data = { category: categoryName };
		setModalContent(data);
	}, [currentUser]);

	return (
		<div className='container'>
			<div className='header' style={{ color: color }}>
				<div className={styles.header_content}>
					<div className={styles.label}>
						<input
							className={styles.color_input}
							type='color'
							value={color}
							onChange={handleColorChange}
							onBlur={onUpdateColor}
							style={{ backgroundColor: color }}
						/>
						<h1>{capitalize(categoryName)}</h1>
					</div>
					<div className={styles.counter}>
						<span>{countNestedValues(items, 'purchased')}</span>
						<span>/</span>
						<span>{items.length}</span>
					</div>
				</div>
			</div>

			<Input type='text' placeholder='Search item' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />

			<div className={styles.checkboxes}>
				<label>
					<input type='checkbox' checked={showPurchasedOnly === true} onChange={() => setShowPurchasedOnly(true)} />
					Purchased
				</label>
				<label>
					<input type='checkbox' checked={showPurchasedOnly === false} onChange={() => setShowPurchasedOnly(false)} />
					Non-Purchased
				</label>
				<label>
					<input type='checkbox' checked={showPurchasedOnly === null} onChange={() => setShowPurchasedOnly(null)} />
					Show All
				</label>
			</div>

			{categoryName && items.length > 0 && (
				<div className={styles.cards_container}>
					{filteredItems.map((item) => (
						<Card key={item.label} data={{ item, category: { color: color, name: categoryName } }} />
					))}
				</div>
			)}

			<Button onClick={handleRemoveCategory}>Delete category</Button>
		</div>
	);
};

export default CategoryRoute;

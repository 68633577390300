import { ThemeColorSetPaletteModel } from '~/types/theme.types';

const rgbToHex = (r: number, g: number, b: number): string => {
	const hex = ((r << 16) | (g << 8) | b).toString(16);
	return '#' + hex.padStart(6, '0');
};

const darkenHexColor = (hex: string, amount: number): string => {
	let [r, g, b] = hexToRgb(hex);
	r = Math.max(0, r - Math.round((r * amount) / 100));
	g = Math.max(0, g - Math.round((g * amount) / 100));
	b = Math.max(0, b - Math.round((b * amount) / 100));
	return rgbToHex(r, g, b);
};

const lightenHexColor = (hex: string, amount: number): string => {
	let [r, g, b] = hexToRgb(hex);
	r = Math.min(255, r + Math.round((r * amount) / 100));
	g = Math.min(255, g + Math.round((g * amount) / 100));
	b = Math.min(255, b + Math.round((b * amount) / 100));
	return rgbToHex(r, g, b);
};

const hexToRgb = (hex: string): number[] => {
	const bigint = parseInt(hex.slice(1), 16);
	const r = (bigint >> 16) & 255;
	const g = (bigint >> 8) & 255;
	const b = bigint & 255;
	return [r, g, b];
};

const contrastHexColor = (surfaceHex: string, fontLightHex: string, fontDarkHex: string): string => {
	const [r, g, b] = hexToRgb(surfaceHex);
	const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

	const result = luminance > 0.5 ? fontDarkHex : fontLightHex;

	return result;
};

type ThemeObject = { [key: string]: ThemeColorSetPaletteModel };

let rootStyleSheet: CSSStyleSheet | null = null;

const updateRootCssVariables = (cssVariables: string) => {
	if (!rootStyleSheet) {
		const styleElement = document.createElement('style');
		document.head.appendChild(styleElement);
		rootStyleSheet = styleElement.sheet as CSSStyleSheet;
	}

	for (let i = 0; i < rootStyleSheet.cssRules.length; i++) {
		if ((rootStyleSheet.cssRules[i] as CSSStyleRule).selectorText === ':root') {
			rootStyleSheet.deleteRule(i);
			break;
		}
	}

	rootStyleSheet.insertRule(cssVariables, 0);
};

const generateCssVariables = (themes: Array<ThemeObject>): void => {
	let cssVariables = ':root {\n';
	for (const theme of themes) {
		for (const [themeKey, shades] of Object.entries(theme)) {
			for (const [shadeKey, colors] of Object.entries(shades)) {
				cssVariables += `  --${themeKey}-${shadeKey}-surface: ${colors.surface};\n`;
				cssVariables += `  --${themeKey}-${shadeKey}-text: ${colors.text};\n`;
			}
		}
	}
	cssVariables += '}';

	updateRootCssVariables(cssVariables);
};

export { darkenHexColor, lightenHexColor, contrastHexColor, generateCssVariables };

import { initializeApp } from "@firebase/app";
import { getAuth } from "@firebase/auth";
import { getFirestore } from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC7PtG-cUE0Pn_xGxAo1IJHIKPGoJXaZXE",
  authDomain: "baby-list-de8c0.firebaseapp.com",
  projectId: "baby-list-de8c0",
  storageBucket: "baby-list-de8c0.appspot.com",
  messagingSenderId: "1039069626118",
  appId: "1:1039069626118:web:c0d01127f89a59cf0ee887"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
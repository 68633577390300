import React from 'react';
import { useForm } from 'react-hook-form';
import Button from '~/components/button/button.component';
import Divider from '~/components/divider/divider.component';
import Input from '~/components/input/input.component';
import ReactQueryHooks from '~/hooks/react-query.hooks';
import { ButtonTypeEnum } from '~/types/button.types';
import { UserModel } from '~/types/user.types';
import { useTheme } from '~/hooks/theme.hooks';
import { ThemeModeOptions } from '~/types/theme.types';
import styles from './profile.module.scss';

const ProfileRoute: React.FC = () => {
	const { theme, setTheme } = useTheme();
	const { data: currentUser } = ReactQueryHooks.currentUser();

	const updateUserProfile = ReactQueryHooks.updateUserProfile();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<UserModel>({
		defaultValues: currentUser,
	});

	const handleUpdateProfile = (data: UserModel) => {
		if (currentUser && currentUser.uid) {
			try {
				updateUserProfile.mutate({
					uid: currentUser.uid,
					displayName: data.displayName,
					email: data.email,
					password: data.password,
				});
			} catch (error: any) {
				console.log('error', error.message);
			}
		} else {
			console.error('No current user available');
		}
	};

	const handleUpdateUserTheme = (preferredTheme: ThemeModeOptions) => {
		if (currentUser && currentUser.uid) {
			try {
				updateUserProfile.mutate({
					uid: currentUser.uid,
					preferredTheme: preferredTheme,
					showToaster: false,
				});
				setTheme({
					mode: preferredTheme,
				});
			} catch (error: any) {
				console.log('error', error.message);
			}
		} else {
			console.error('No current user available');
		}
	};

	return (
		<div className='container'>
			<div className='header'>
				<h1>Profile</h1>
			</div>
			<div className={styles.avatar} style={{ backgroundImage: `url(${currentUser?.photoURL})` }} />
			<form onSubmit={handleSubmit(handleUpdateProfile)}>
				<div className={styles.radios}>
					<label htmlFor='theme' onClick={() => handleUpdateUserTheme('light')}>
						<legend>Theme</legend>
						<Input
							disabled={theme.mode === 'dark'}
							type='text'
							value='Light'
							// register={{ ...register('displayName', { required: FormValidationEnum.REQUIRED }) }}
						/>
					</label>
					<label htmlFor='theme' onClick={() => handleUpdateUserTheme('dark')}>
						<Input
							disabled={theme.mode === 'light'}
							type='text'
							value='Dark'
							// register={{ ...register('displayName', { required: FormValidationEnum.REQUIRED }) }}
						/>
					</label>
				</div>
				<Divider />
				<label htmlFor='displayName'>
					<legend>Display Name</legend>
					<Input type='text' placeholder='Username' register={{ ...register('displayName') }} error={errors.displayName} />
				</label>
				<label htmlFor='email'>
					<legend>Email</legend>
					<Input type='email' placeholder='Email' register={{ ...register('email') }} error={errors.email} />
				</label>
				<label htmlFor='password'>
					<legend>Password</legend>
					<Input type='password' placeholder='Password' register={{ ...register('password') }} error={errors.password} />
				</label>
				<div className={`form_footer ${styles.submit}`}>
					<Button type={ButtonTypeEnum.Submit}>
						<i className='ri-arrow-right-line' />
					</Button>
				</div>
			</form>
		</div>
	);
};

export default ProfileRoute;

import { useLastLocation } from '~/hooks/location.hooks';
import { useModal } from '~/hooks/modal.hooks';
import CategoryModal from './category-modal/category-modal.component';
import { VariantTypes } from '~/types/variant.types';
import styles from './modal.module.scss';
import Button from '~/components/button/button.component';
import ItemModal from './item-modal/item-modal.component';

const Modal = () => {
	const location = useLastLocation();
	const currentLocation = location?.currentLocation || '';
	const { isModalOpen, closeModal } = useModal();

	if (!isModalOpen) {
		return null;
	}

	return (
		<div className={styles.modal} onClick={closeModal}>
			{currentLocation.includes('private/dashboard') && <CategoryModal />}
			{currentLocation.includes('private/category') && <ItemModal />}
			<Button variant={VariantTypes.Text} className={styles.close} onClick={closeModal}>
				<i className='ri-close-line' />
			</Button>
		</div>
	);
};

export default Modal;

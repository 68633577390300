import type { ThemeColorSetPaletteModel } from '~/types/theme.types';
import { contrastHexColor } from './colors.function';

type RGB = [number, number, number];
type HSL = [number, number, number];

const hueToRgb = (p: number, q: number, t: number): number => {
	if (t < 0) t += 1;
	if (t > 1) t -= 1;
	if (t < 1 / 6) return p + (q - p) * 6 * t;
	if (t < 1 / 2) return q;
	if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
	return p;
};

const hexToRgb = (hex: string): RGB => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : [0, 0, 0];
};

const rgbToHex = (rgb: RGB): string => '#' + rgb.map((x) => x.toString(16).padStart(2, '0')).join('');

const rgbToHsl = ([r, g, b]: RGB): HSL => {
	r /= 255;
	g /= 255;
	b /= 255;
	const max = Math.max(r, g, b);
	const min = Math.min(r, g, b);
	let h = (max + min) / 2;
	let s = h;
	const l = h;

	if (max === min) {
		h = s = 0;
	} else {
		const d = max - min;
		s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
		switch (max) {
			case r:
				h = (g - b) / d + (g < b ? 6 : 0);
				break;
			case g:
				h = (b - r) / d + 2;
				break;
			case b:
				h = (r - g) / d + 4;
				break;
		}
		h /= 6;
	}

	return [h * 360, s * 100, l * 100];
};

const hslToRgb = ([h, s, l]: HSL): RGB => {
	h /= 360;
	s /= 100;
	l /= 100;

	const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
	const p = 2 * l - q;
	return [Math.round(hueToRgb(p, q, h + 1 / 3) * 255), Math.round(hueToRgb(p, q, h) * 255), Math.round(hueToRgb(p, q, h - 1 / 3) * 255)];
};

const genShades = (hexColor: string, steps = 5, lightnessChange = 5): string[] => {
	const rgbColor = hexToRgb(hexColor);
	const hslColor = rgbToHsl(rgbColor);
	const palette: string[] = [];

	for (let i = -(steps - 1) / 2; i <= (steps - 1) / 2; i++) {
		const newHslColor = [...hslColor] as HSL;
		newHslColor[2] = Math.max(0, Math.min(100, hslColor[2] + i * lightnessChange));
		const newRgbColor = hslToRgb(newHslColor);
		palette.push(rgbToHex(newRgbColor));
	}

	return palette;
};

const genColorSetPalette = (surfaceHex: string, fontLightHex: string, fontDarkHex: string): ThemeColorSetPaletteModel => {
	const surface = genShades(surfaceHex);
	const keys: (keyof ThemeColorSetPaletteModel)[] = ['darker', 'dark', 'base', 'light', 'lighter'];
	const palette: ThemeColorSetPaletteModel = {} as ThemeColorSetPaletteModel;

	for (const [index, color] of surface.entries()) {
		const key = keys[index];
		palette[key] = {
			surface: color,
			text: contrastHexColor(color, fontLightHex, fontDarkHex),
		};
	}

	return palette;
};

export { genColorSetPalette };

import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { useTheme } from '~/hooks/theme.hooks';

const GlobalStyle = createGlobalStyle`
	body {
		background-color: ${(props) => props.theme.main.base.surface};
		color: ${(props) => props.theme.main.base.text};
	}
`;

const CssBaseline: React.FC = () => {
	const { theme } = useTheme();

	return (
		<ThemeProvider theme={theme}>
			<GlobalStyle />
		</ThemeProvider>
	);
};

export default CssBaseline;

import { ItemWithCategory, UserModel } from '~/types/user.types';
import { capitalize } from '~/functions/utils.functions';
import { useState } from 'react';
import Button from '../button/button.component';
import styles from './card.module.scss';
import { ReactQueryEnum } from '~/enums/react-query.enums';
import ReactQueryHooks from '~/hooks/react-query.hooks';

interface CardProps {
	data: ItemWithCategory;
	className?: string;
	onClick?: () => void;
}

const Card: React.FC<CardProps> = ({ data, className, onClick }) => {
	const item = data.item;
	const category = data.category;

	const currentUser = ReactQueryHooks.read<UserModel>(ReactQueryEnum.CURRENT_USER);
	const updateItemMutation = ReactQueryHooks.updateItemInCategory();
	const removeItemFromCategoryMutation = ReactQueryHooks.removeItemFromCategory();
	const [modal, setModal] = useState(false);

	const handleOnClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		event.stopPropagation();
		setModal(!modal);
		onClick && onClick();
	};

	const onUpdateItem = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.stopPropagation();
		const { uid } = currentUser || {};
		if (uid && item) {
			updateItemMutation.mutate({
				uid,
				categoryName: category.name,
				oldItem: item,
				newItem: { ...item, purchased: !item.purchased },
			});
		}
	};

	const handleRemoveItem = () => {
		const { uid } = currentUser || {};
		if (uid && category.name && item) {
			removeItemFromCategoryMutation.mutate({ uid, categoryName: category.name, item });
		}
	};

	return (
		<div className={className ? `${className} ${styles.card}` : styles.card} onClick={handleOnClick}>
			<div className={styles.card_content}>
				<div className={styles.flag} style={{ backgroundColor: category.color }} />
				<div className={styles.content}>
					<div className={styles.label}>{capitalize(item.label)}</div>
					<div className='actions'>
						<button
							className={styles.button}
							style={{ backgroundColor: item.purchased ? category.color || 'var(--main-base-text)' : undefined }}
							onClick={onUpdateItem}
						/>
					</div>
				</div>
			</div>

			{modal && (
				<div className={styles.modal}>
					<Button>Edit</Button>
					<Button onClick={handleRemoveItem}>Delete</Button>
				</div>
			)}
		</div>
	);
};

export default Card;

import { toast } from 'react-toastify';

import {
	GoogleAuthProvider,
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	signInWithPopup,
	signOut,
	updateEmail,
	updatePassword,
	updateProfile,
} from '@firebase/auth';
import { auth, db } from '../utils/firebase.utils';
import { doc, getDoc, setDoc, updateDoc, arrayUnion, arrayRemove, deleteField, addDoc, collection } from '@firebase/firestore';
import { FirebaseCollectionEnum } from '~/enums/firebase.enums';
import { Item, UserModel } from '~/types/user.types';
import { ThemeModeOptions } from '~/types/theme.types';

const provider = new GoogleAuthProvider();

const useSignInWithGoogle = async () => {
	try {
		const { user } = await signInWithPopup(auth, provider);

		if (user) {
			const userRef = doc(db, FirebaseCollectionEnum.USERS, user.uid);
			const userSnapshot = await getDoc(userRef);

			if (!userSnapshot.exists()) {
				const { displayName, email, photoURL } = user;
				try {
					const userData = {
						displayName,
						email,
						photoURL,
					};
					await setDoc(userRef, userData, { merge: true });
					return toast.success(`Welcome ${displayName}!`);
				} catch (error: any) {
					console.error('Error creating user', error.message);
				}
			}
		}
	} catch (error: any) {
		console.error('Error signing in', error.message);
	}
};

const useSignInWithEmailAndPassword = async (email: string, password: string) => {
	try {
		const { user } = await signInWithEmailAndPassword(auth, email, password);

		if (user) {
			const userRef = doc(db, FirebaseCollectionEnum.USERS, user.uid);
			const userSnapshot = await getDoc(userRef);

			if (!userSnapshot.exists()) {
				const { displayName, email, photoURL } = user;
				const userData = {
					displayName,
					email,
					photoURL,
				};
				await setDoc(userRef, userData, { merge: true });
				return toast.success(`Welcome ${displayName}!`);
			}
		}
	} catch (error: any) {
		console.error('Error signing in', error.message);
	}
};

const useSignUpWithEmailAndPassword = async (email: string, password: string, displayName: string) => {
	try {
		const { user } = await createUserWithEmailAndPassword(auth, email, password);

		if (user) {
			const userRef = doc(db, FirebaseCollectionEnum.USERS, user.uid);
			const userSnapshot = await getDoc(userRef);

			if (!userSnapshot.exists()) {
				const userData = {
					displayName,
					email,
				};
				await setDoc(userRef, userData, { merge: true });
				return toast.success(`Welcome ${displayName}!`);
			}
		}
	} catch (error: any) {
		console.error('Error signing up', error.message);
	}
};

const useSignOut = async () => {
	await signOut(auth);
	return toast.info('See you next time!');
};

const getUserData = async (userId?: string) => {
	const uid = userId || (auth.currentUser ? auth.currentUser.uid : undefined);
	if (!uid) throw new Error('No user ID available');
	const userRef = doc(db, FirebaseCollectionEnum.USERS, uid);
	const userSnapshot = await getDoc(userRef);
	return { uid, ...userSnapshot.data() } as UserModel;
};

const useUpdateUserProfile = async (
	uid: string,
	displayName?: string,
	password?: string,
	email?: string,
	preferredTheme?: ThemeModeOptions,
	showToaster: boolean = true
) => {
	try {
		const userRef = doc(db, FirebaseCollectionEnum.USERS, uid);
		if (displayName && auth.currentUser) {
			await updateDoc(userRef, { displayName });
		}
		if (displayName && auth.currentUser) {
			await updateProfile(auth.currentUser, { displayName });
		}
		if (password && auth.currentUser) {
			await updatePassword(auth.currentUser, password);
		}
		if (email && auth.currentUser) {
			await updateEmail(auth.currentUser, email);
			const userRef = doc(db, FirebaseCollectionEnum.USERS, auth.currentUser.uid);
			await updateDoc(userRef, { email: email });
		}
		if (preferredTheme && auth.currentUser) {
			await updateDoc(userRef, { preferredTheme });
		}
		showToaster && toast.success('Profile updated successfuly.');
	} catch (error: any) {
		console.error('Error updating profile', error.message);
	}
};

const addCategory = async (uid: string, categoryName: string, color: string) => {
	const userRef = doc(db, FirebaseCollectionEnum.USERS, uid);
	const categoryData = {
		['categories.' + categoryName.toLowerCase()]: {
			color,
			items: [],
		},
	};
	await updateDoc(userRef, categoryData);
	return toast.success(`${categoryName} successfuly added.`);
};

const removeCategory = async (uid: string, categoryName: string) => {
	const userRef = doc(db, FirebaseCollectionEnum.USERS, uid);
	await updateDoc(userRef, { ['categories.' + categoryName]: deleteField() });
	return toast.success(`${categoryName} successfuly removed.`);
};

const updateCategoryName = async (uid: string, oldCategoryName: string, newCategoryName: string) => {
	const userRef = doc(db, FirebaseCollectionEnum.USERS, uid);
	const categoryData = await getDoc(userRef);
	const items = categoryData.data()?.categories?.[oldCategoryName] || [];
	await updateDoc(userRef, { ['categories.' + oldCategoryName]: deleteField() });
	await updateDoc(userRef, { ['categories.' + newCategoryName]: items });
	return toast.success(`${oldCategoryName} successfuly renamed to ${newCategoryName}.`);
};

const updateCategoryColor = async (uid: string, categoryName: string, color: string) => {
	const userRef = doc(db, FirebaseCollectionEnum.USERS, uid);
	await updateDoc(userRef, { [`categories.${categoryName}.color`]: color });
	return toast.success(`Color for category ${categoryName} successfully updated.`);
};

const addItemToCategory = async (uid: string, categoryName: string, item: Omit<Item, 'uid' | 'added' | 'purchased'>) => {
	const userRef = doc(db, FirebaseCollectionEnum.USERS, uid);
	const itemCollectionRef = collection(db, 'items');
	const newItemRef = await addDoc(itemCollectionRef, {});
	const itemId = newItemRef.id;
	const itemWithId = { uid: itemId, added: new Date(), purchased: false, ...item };
	await updateDoc(userRef, { ['categories.' + categoryName + '.items']: arrayUnion(itemWithId) });
	return toast.success(`${item.label} successfuly added.`);
};

const removeItemFromCategory = async (uid: string, categoryName: string, item: Item) => {
	const userRef = doc(db, FirebaseCollectionEnum.USERS, uid);
	await updateDoc(userRef, { ['categories.' + categoryName + '.items']: arrayRemove(item) });
	return toast.success(`${item.label} successfuly removed.`);
};

// const removeItemFromCategory = async (uid: string, categoryName: string, item: Item) => {
// 	const userRef = doc(db, FirebaseCollectionEnum.USERS, uid);
// 	const userDoc = await getDoc(userRef);
// 	const userData = userDoc.data();
// 	const existingItems: Item[] = userData?.categories[categoryName]?.items || [];
// 	const newItems = existingItems.filter((i: Item) => i.uid !== item.uid);
// 	await updateDoc(userRef, { ['categories.' + categoryName + '.items']: newItems });
// 	return toast.warning('Item successfully removed.');
// };

const updateItemInCategory = async (uid: string, categoryName: string, oldItem: Item, newItem: Item) => {
	const userRef = doc(db, FirebaseCollectionEnum.USERS, uid);
	const userDoc = await getDoc(userRef);
	const userData = userDoc.data();
	const updatedCategory = userData?.categories?.[categoryName].items?.map((item: Item) =>
		item.uid === oldItem.uid ? newItem : item
	);
	await updateDoc(userRef, { ['categories.' + categoryName + '.items']: updatedCategory });
	try {
		await updateDoc(userRef, { ['categories.' + categoryName + '.items']: updatedCategory });
		return toast.success(`${oldItem.label} successfully updated.`);
	} catch (error) {
		console.error('Failed to update item:', error);
	}
};

const FirebaseHooks = {
	signInWithGoogle: useSignInWithGoogle,
	signInWithEmailAndPassword: useSignInWithEmailAndPassword,
	signUpWithEmailAndPassword: useSignUpWithEmailAndPassword,
	signOut: useSignOut,
	getUser: getUserData,
	updateUserProfile: useUpdateUserProfile,
	addCategory,
	removeCategory,
	updateCategoryName,
	updateCategoryColor,
	addItemToCategory,
	removeItemFromCategory,
	updateItemInCategory,
};

export default FirebaseHooks;
